import { lazy } from "react";

import i18n from "../i18n";
import * as myRouters from "../Utils/routerConstants";

const FrontendLayout = lazy(() => import("../Frontend/Layout/FrontendLayout"));
const FrontendLayoutAccount = lazy(() =>
  import("../Frontend/Layout/FrontendLayoutAccount")
);
const FrontendLayoutAccount_Skill = lazy(() =>
  import("../Frontend/Layout/FrontendLayoutAccount_Skill")
);

const HomePage = lazy(() => import("../Frontend/Pages/Home/HomePage"));
const LoginPage = lazy(() =>
  import("../Frontend/Pages/Account/Login/LoginPage")
);
const ActiveAccountPage = lazy(() =>
  import("../Frontend/Pages/Account/ActiveAccount/ActiveAccountPage")
);
const ResendActivationLinkPage = lazy(() =>
  import(
    "../Frontend/Pages/Account/ResendActivationLink/ResendActivationLinkPage"
  )
);
const ForgotPasswordPage = lazy(() =>
  import("../Frontend/Pages/Account/ForgotPassword/ForgotPasswordPage")
);
const ResetPasswordPage = lazy(() =>
  import("../Frontend/Pages/Account/ResetPassword/ResetPasswordPage")
);
const RegisterPage = lazy(() =>
  import("../Frontend/Pages/Account/Register/RegisterPage")
);
const LoginGooglePage = lazy(() =>
  import("../Frontend/Pages/Account/Login/LoginGooglePage")
);

// MY SKILL ==================

const EnglishSkillIndexPage = lazy(() =>
  import("../Frontend/Pages/MyCourse/EnglishSkill/EnglishSkillIndexPage")
);
const SoftSkillIndexPage = lazy(() =>
  import("../Frontend/Pages/MyCourse/SoftSkill/SoftSkillIndexPage")
);

// English skill
const EnglishSkillReflectiveResponseByTopicPage = lazy(() =>
  import(
    "../Frontend/Pages/MyCourse/EnglishSkill/EnglishSkillReflectiveResponseByTopicPage"
  )
);
const EnglishSkillReflectiveResponseByOnDemandPage = lazy(() =>
  import(
    "../Frontend/Pages/MyCourse/EnglishSkill/EnglishSkillReflectiveResponseByOnDemandPage"
  )
);
const EnglishSkillExerciseByTopicPage = lazy(() =>
  import(
    "../Frontend/Pages/MyCourse/EnglishSkill/EnglishSkillByTopic/EnglishSkillExerciseByTopicPage"
  )
);
const EnglishSkillExerciseByDemandPage = lazy(() =>
  import(
    "../Frontend/Pages/MyCourse/EnglishSkill/EnglishSkillByDemand/EnglishSkillExerciseByDemandPage"
  )
);

// Soft skill
const SoftSkillFoundationPage = lazy(() =>
  import(
    "../Frontend/Pages/MyCourse/SoftSkill/SoftSkillFoundationPage"
  )
);
const SoftSkillFoundationKnowledgePage = lazy(() =>
  import(
    "../Frontend/Pages/MyCourse/SoftSkill/SoftSkillFoundationKnowledge/SoftSkillFoundationKnowledgePage"
  )
);
const SoftSkillPracticingPage = lazy(() =>
  import(
    "../Frontend/Pages/MyCourse/SoftSkill/SoftSkillPracticingPage"
  )
);
const SoftSkillPracticingByLessonPage = lazy(() =>
  import(
    "../Frontend/Pages/MyCourse/SoftSkill/SoftSkillPracticingByLesson/SoftSkillPracticingByLessonPage"
  )
);

// 

const routers = [
  {
    exact: true,
    path: "/",
    name: i18n.t("Home"),
    component: HomePage,
    layout: FrontendLayout,
  },
  {
    path: "/login",
    name: i18n.t("Login"),
    component: LoginPage,
    layout: FrontendLayoutAccount,
  },
  {
    path: "/dang-nhap-ctv",
    name: i18n.t("Login"),
    component: LoginGooglePage,
    layout: FrontendLayoutAccount,
  },
  {
    path: "/forgot-password",
    name: i18n.t("Forgot password"),
    component: ForgotPasswordPage,
    layout: FrontendLayoutAccount,
  },
  {
    path: "/reset-password",
    name: i18n.t("Reset password"),
    component: ResetPasswordPage,
    layout: FrontendLayoutAccount,
  },
  {
    path: "/register",
    name: i18n.t("Register"),
    component: RegisterPage,
    layout: FrontendLayoutAccount,
  },
  {
    path: "/active-account",
    name: i18n.t("Active account"),
    component: ActiveAccountPage,
    layout: FrontendLayoutAccount,
  },
  {
    path: "/resend-link-active",
    name: i18n.t("Resend active link"),
    component: ResendActivationLinkPage,
    layout: FrontendLayoutAccount,
  },
  // 
  // ENGLISH SKILL
  {
    path: myRouters.MY_SKILL_ENGLISH_WORK_INDEX_LIST_PAGE_URL,
    name: "Luyện kỹ năng Tiếng Anh hỗ trợ giao tiếp chuyên môn với khách hàng",
    component: EnglishSkillIndexPage,
    layout: FrontendLayoutAccount_Skill
  }, 
  {
    path: myRouters.MY_SKILL_ENGLISH_RESPOND_REFLEXIVELY_BY_TOPIC_PAGE_URL,
    name: "Luyện kỹ năng tiếng Anh đối đáp phản xạ theo CHỦ ĐỀ",
    component: EnglishSkillReflectiveResponseByTopicPage,
    layout: FrontendLayoutAccount_Skill
  },
  {
    path: myRouters.MY_SKILL_ENGLISH_RESPOND_REFLEXIVELY_BY_DEMAND_PAGE_URL,
    name: "Luyện kỹ năng tiếng Anh đối đáp phản xạ theo NHU CẦU",
    component: EnglishSkillReflectiveResponseByOnDemandPage,
    layout: FrontendLayoutAccount_Skill
  },
  {
    path: myRouters.URL_KEY_NOT_AUTH + 'english-skill-exercise-by-topic/:category_level2_id/:topic_slug/:topic_name',
    name: "Luyện kỹ năng tiếng Anh đối đáp phản xạ theo CHỦ ĐỀ",
    component: EnglishSkillExerciseByTopicPage,
    layout: FrontendLayoutAccount_Skill
  },
  {
    path: myRouters.URL_KEY_NOT_AUTH + 'english-skill-exercise-by-demand/:category_level2_id/:topic_slug/:topic_name',
    name: "Luyện kỹ năng tiếng Anh đối đáp phản xạ theo NHU CẦU",
    component: EnglishSkillExerciseByDemandPage,
    layout: FrontendLayoutAccount_Skill
  },

  // SOFT SKILL
  {
    path: myRouters.MY_SKILL_SOFT_WORK_INDEX_LIST_PAGE_URL,
    name: "Luyện kỹ năng MỀM hỗ trợ giao tiếp trong đời sống",
    component: SoftSkillIndexPage,
    layout: FrontendLayoutAccount_Skill
  },
  {
    path: myRouters.MY_SKILL_SOFT_FOUNDATION_PAGE_URL,
    name: "Nền tảng kỹ năng MỀM",
    component: SoftSkillFoundationPage,
    layout: FrontendLayoutAccount_Skill
  },
  {
    path: myRouters.URL_KEY_NOT_AUTH + 'soft-skill-foundation-knowledge/:category_level2_id/:topic_slug/:topic_name',
    name: "Nền tảng kỹ năng giao tiếp",
    component: SoftSkillFoundationKnowledgePage,
    layout: FrontendLayoutAccount_Skill
  },
  // ===
  {
    path: myRouters.MY_SKILL_SOFT_PRACTICING_PAGE_URL,
    name: "Thực hành kỹ năng MỀM vào đời sống",
    component: SoftSkillPracticingPage,
    layout: FrontendLayoutAccount_Skill
  },
  {
    path: myRouters.URL_KEY_NOT_AUTH + 'soft-skill-practicing-by-lesson/:category_level2_id/:topic_slug/:topic_name',
    name: "Thực hành kỹ năng giao tiếp",
    component: SoftSkillPracticingByLessonPage,
    layout: FrontendLayoutAccount_Skill
  },
];

export default routers;
